
.loading-mask {
    position: relative;
}

.loading-mask::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.loading-mask::after {
    content: "";
    position: absolute;
    border-width: 3px;
    border-style: solid;
    border-color: transparent rgb(255, 255, 255) rgb(255, 255, 255);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    animation: 2s linear 0s normal none infinite running spin;
    filter: drop-shadow(0 0 2 rgba(0, 0, 0, 0.33));
}