
@import "../spinner.css";

.scanner{
    margin-bottom: 3.5em;
    border: 2px solid;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 7em;
    height: 7em;
}

.scan-clean {
    display: none;
}
.scan-clean-complete.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
}
.scan-clean-complete:after {
    opacity: 1;
    height: 3.5em;
    width: 1.75em;
    transform-origin: left top;
    border-right: 3px solid #5cb85c;
    border-top: 3px solid #5cb85c;
    content: "";
    left: 1.75em;
    top: 3.5em;
    position: absolute;
}

.scan-warn {
    display: none;
}

.scan-warn-complete-mac {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 32px;
    height: 32px;
    opacity: 1;
}

.scan-warn-complete {
    position: absolute;
    right: 11px;
    top: 8px;
    width: 32px;
    height: 32px;
    opacity: 1;
}

.scan-warn-complete.draw:after {
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: warn;
}

.scan-warn-complete.draw:before {
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: warn;
}


.scan-warn-complete:before {
    position: absolute;
    top: 10px;
    left: -15px;
    content: ' ';
    height: 80px;
    width: 3px;
    background-color: red;
    transform: rotate(45deg);
}

.scan-warn-complete:after {
    position: absolute;
    top: 10px;
    left: -15px;
    content: ' ';
    height: 80px;
    width: 3px;
    background-color: red;
    transform: rotate(-45deg);
}

.scan-warn-complete-mac.draw:after {
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: warn;
}

.scan-warn-complete-mac.draw:before {
    animation-duration: 400ms;
    animation-timing-function: ease;
    animation-name: warn;
}


.scan-warn-complete-mac:before {
    position: absolute;
    top: 10px;
    left: -15px;
    content: ' ';
    height: 80px;
    width: 3px;
    background-color: red;
    transform: rotate(45deg);
}

.scan-warn-complete-mac:after {
    position: absolute;
    top: 10px;
    left: -15px;
    content: ' ';
    height: 80px;
    width: 3px;
    background-color: red;
    transform: rotate(-45deg);
}

.scan-clean-complete-circle {
    -webkit-animation: none;
    animation: none;
    border-width: 2px;
    transition: border 500ms ease-out;
}

.scan-warn-complete-circle {
    -webkit-animation: none;
    animation: none;
    border-color: crimson;
    border-width: 2px;
    transition: border 500ms ease-out;
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 1.75em;
        opacity: 1;
    }
    40% {
        height: 3.5em;
        width: 1.75em;
        opacity: 1;
    }
    100% {
        height: 3.5em;
        width: 1.75em;
        opacity: 1;
    }
}

@keyframes warn {
    0% {
       width: 0px;
        opacity: 0;
    }
    33% {
        width: 1px;
        opacity: 0.25;
    }
    66% {
        width: 2px;
        opacity: 0.4;
    }
    100% {
        width: 3px;
        opacity: 1;
    }
}
