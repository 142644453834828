.linkscan-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}
.linkscan-button--clean {
  color: black;
  background-color: #5cb85c;
}
.linkscan-button--warn {
  color: #fff;
  background-color: crimson;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.linkscan-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.linkscan-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.linkscan-button--large {
  font-size: 16px;
  padding: 12px 24px;
}
